body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Quicksand', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 15px;
}

html {
  scroll-behavior: smooth
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* .buttoncustom:hover {
  background-color: rgb(26, 67, 126)!important;
  border: 1px solid rgb(26, 67, 126) !important;
  transition: all 0.4s ease 0s !important;
} */

.buttoncustom {
  background-color: #E36D2E !important;
  border: 1px solid #E36D2E !important;
  font-size: 18px !important;
}

.order-grid:hover {
  webkit-transition: all 0.2s ease 0s !important;
  transition: all 0.2s ease 0s !important;
  box-shadow: 0px 0px 20px rgb(0 0 0 / 10%);
  border-color: #ffffff !important;
  margin-top: -2%;
}

.buttonView:hover {
  background-color: #e83e8c;
  color: #ffffff !important;
  transition: all 0.4s ease 0s !important;
}

.linkFooter:hover {
  color: #E36D2E !important;
  padding-left: 6px !important;
  margin-top: -6px !important;
  transition: all 0.4s ease 0s;
  font-weight: bold;
}

.linkFooter {
  color: #8a99b3 !important;
  margin-bottom: 12px !important;
  transition: all 0.4s ease 0s;
  text-transform: capitalize;
}

.SrollTop:hover {
  opacity: 1 !important;
  transition: all 0.4s ease 0s;
}

#search>.ant-row>.ant-col>.ant-form-item-explain>.ant-form-item-explain-error {
  text-align: left !important;
  font-weight: 600;
}

#search>.ant-row>.ant-col>.capitalize>.ant-input {
  text-transform: capitalize !important;
}

.colorFooter {
  color: #19447F !important;
}

/* Tạo hiệu ứng tuyết rơi */
.snow-container{position:fixed;width:100%;max-width:100%;z-index:99999;pointer-events:none;overflow:hidden;top:0;height:100%}

.snow{display:block;position:absolute;z-index:2;top:0;right:0;bottom:0;left:0;pointer-events:none;-webkit-transform:translate3d(0,-100%,0);transform:translate3d(0,-100%,0);-webkit-animation:snow linear infinite;animation:snow linear infinite}

.snow.foreground{background-image:url("https://dl6rt3mwcjzxg.cloudfront.net/assets/snow/snow-large-075d267ecbc42e3564c8ed43516dd557.png");-webkit-animation-duration:15s;animation-duration:15s;}

.snow.foreground.layered{-webkit-animation-delay:7.5s;animation-delay:7.5s}

.snow.middleground{background-image:url("https://dl6rt3mwcjzxg.cloudfront.net/assets/snow/snow-medium-0b8a5e0732315b68e1f54185be7a1ad9.png");-webkit-animation-duration:20s;animation-duration:20s;}

.snow.middleground.layered{-webkit-animation-delay:10s;animation-delay:10s}

.snow.background{background-image:url("https://dl6rt3mwcjzxg.cloudfront.net/assets/snow/snow-small-1ecd03b1fce08c24e064ff8c0a72c519.png");-webkit-animation-duration:25s;animation-duration:25s;}

.snow.background.layered{-webkit-animation-delay:12.5s;animation-delay:12.5s}

@-webkit-keyframes snow{0%{-webkit-transform:translate3d(0,-100%,0);transform:translate3d(0,-100%,0)}100%{-webkit-transform:translate3d(5%,100%,0);transform:translate3d(5%,100%,0)}}

@keyframes snow{0%{-webkit-transform:translate3d(0,-100%,0);transform:translate3d(0,-100%,0)}100%{-webkit-transform:translate3d(5%,100%,0);transform:translate3d(5%,100%,0)}}
/* Kết thức hiệu ứng tuyết rơi */